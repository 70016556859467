import React from 'react'
import user1 from './images/user1.jpeg'
import user2 from './images/user2.jpeg'
import user3 from './images/user3.jpeg'
import background from './images/galleryBackground.png'
import photo1 from './images/Screenshot_20240614_194940_Gallery.jpg'
import photo2 from './images/Screenshot_20240614_195052_Gallery.jpg'
import photo3 from './images/Screenshot_20240614_195039_Gallery.jpg'
import photo4 from './images/Screen4.jpg'
import photo5 from './images/Screen5.jpg'
import photo6 from './images/Screen6.jpg'
import photo7 from './images/Screen7.jpg'
import photo8 from './images/Screen8.jpg'
import photo9 from './images/Screen9.jpg'
import photo10 from './images/Screen10.jpg'
import photo11 from './images/Screen11.jpg'
import photo12 from './images/Screen12.jpg'
import photo13 from './images/Screen13.jpg'
import logo from './images/logo.png'
import './Testimonials.css'
import { Link } from 'react-scroll'

const Testimonials = () => {
    return (
        <div  className='testimonials' id='testimonials'>
            <div className='container'>
                <h1 style={{ textAlign: 'center', marginBottom: '15px', paddingTop: '20px' }} >הלקוחות שלנו</h1>
                <div style={{ margin: 'auto', marginBottom: '20px', marginTop: '20px', width: '40%', borderBottom: "2px solid black" }}></div>

               
               
                    <div className='card'>
                    <img class="img" src={photo1} alt='logo' />
                       
                    </div>
                    <div className='card'>
                    <img class="img" src={photo2} alt='logo' />
                    </div>

                    <div class="logoIcon">
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-80} duration={500} >
                    <img class="img" src={logo} alt='logo' />
                    </Link>
                    </div>

                <div className='card'>
                    <img class="img" src={photo3} alt='logo' />
                </div>

                <div className='card'>
                    <img class="img" src={photo4} alt='logo' />
                </div>

                <div class="logoIcon">
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-80} duration={500} >
                        <img class="img" src={logo} alt='logo' />
                    </Link>
                </div>
                <div className='card'>
                    <img class="img" src={photo5} alt='logo' />
                </div>
                <div className='card'>
                    <img class="img" src={photo6} alt='logo' />
                </div>
                <div class="logoIcon">
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-80} duration={500} >
                        <img class="img" src={logo} alt='logo' />
                    </Link>
                </div>
                <div className='card'>
                    <img class="img" src={photo7} alt='logo' />
                </div>
                <div className='card'>
                    <img class="img" src={photo8} alt='logo' />
                </div>

                <div class="logoIcon">
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-80} duration={500} >
                        <img class="img" src={logo} alt='logo' />
                    </Link>
                </div>

                <div className='card'>
                    <img class="img" src={photo9} alt='logo' />
                </div>
                
                <div className='card'>
                    <img class="img" src={photo10} alt='logo' />
                </div>
                <div class="logoIcon">
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-80} duration={500} >
                        <img class="img" src={logo} alt='logo' />
                    </Link>
                </div>
                <div className='card'>
                    <img class="img" src={photo11} alt='logo' />
                </div>
                <div className='card'>
                    <img class="img" src={photo12} alt='logo' />
                </div>
                <div className='card'>
                    <img class="img" src={photo13} alt='logo' />
                </div>
                  
              
            </div>
        </div>
    )
}

export default Testimonials
