import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import {Link} from "react-scroll"
import logo from './images/logo.png'
import Hidden from './Hidden'

import './Navbar.css'

const Navbar = (props) => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

   
    // const hideBarOnScrollDown=()=>{
    //     console.log("windowScroll", window.scrollY)
    //     if(window.scrollY>=90){
    //         setHidden(true)
    //     }
    //     else{
    //         setHidden(false)
    //     }
    // }

    // window.addEventListener('scroll',hideBarOnScrollDown)
    const closeMenu = () => setClick(false)
   


    return (
        <>
            { props.hidden ? <Hidden/> : 
        <div className='header1'>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    <img src={logo} alt='logo' />
                </a>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: 'black' }} />)
                        : (<FaBars size={30} style={{ color: 'black' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                       <Link  to="hero" spy={true} smooth={true} offset={-10} duration={500} onClick={closeMenu}>בית</Link>
                       
                    </li>
                    <li className='nav-item'> 
                    <Link to="about" spy={true} smooth={true} offset={20} duration={500} onClick={closeMenu}>מי אנחנו</Link>
                    
                        
                    </li>
                    <li className='nav-item'>
                         <Link to="Summary" spy={true} smooth={true} offset={-80} duration={500} onClick={closeMenu}>מה בתפריט</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="OrderMenu" spy={true} smooth={true} offset={-10} duration={500} onClick={closeMenu}>הזמנה</Link>
                    </li>
                            <li className='nav-item'>
                                <a href="#Menus" class="btn">
                                <Link to="Menus" spy={true} smooth={true} offset={-10} duration={500} onClick={closeMenu}>תפריטים</Link>
                                </a>
                            </li>
                    <li className='nav-item'>
                      
                        <Link to="testimonials" spy={true} smooth={true} offset={-150} duration={500} onClick={closeMenu}>הלקוחות שלנו</Link>
                       
                    </li>
                    <li className='nav-item'>

                        <Link to="footer" spy={true} smooth={true} offset={-150} duration={500} onClick={closeMenu}>צור קשר </Link>

                    </li>
                </ul>
            </nav>
        </div>
          }
        </>
    )
}

export default Navbar
