import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import '../OrderMenu.css'

import logo from '../images/logo.png'
import {Link} from 'react-scroll'
import orderBtn from '../images/כפתור הזמנה.png'
import limonArak from '../images/9.png'
import lemongrass from '../images/lemonGrass.png'
import pasiflora from '../images/pasiflora.png'
import jinTonic from '../images/margarita.png'
import bottle from '../images/bottle.png'
import bananaTamar from '../images/bananatamar.png'
import tropicalIce from '../images/5.png'
import food from '../images/11.png'
import bananaMango from '../images/mangoBanana.png'
import strawberyVodka from '../images/tot.png'
import crushedIceCocktails from '../images/9.png'
import { FaCircleArrowRight } from "react-icons/fa6";
import backToMenuBtn from '../images/חזרה לתפריט.png'


import { IoArrowBackCircle } from "react-icons/io5";


const CocktailShake = (props) => {


    const handleVisibleRecommended=(text)=>{
        window.open(`https://wa.me/972525233560?text=${text}`);
        
    }
    const handleVisibleCocktailShake = () => {
        props.setVisibleCocktailShake(true)
        props.setVisibleMenu(false)
    }
    const goBack = () => {
        props.setVisibleCocktailShake(false)
        props.setVisibleMenu(true)
    }
    const goToFoodMenu = () => {

        props.setVisibleCocktailShake(false)
        props.setVisibleFoodMenu(true)
    }
    const goBackCrushediceCocktailShake = () => {
        props.setVisibleCocktailShake(false)
        props.setVisibleCrushedIceCocktail(true)
    }
    const handleVisibleBottleMenu = () => {
        props.setVisibleBottleMenu(true)
        props.setVisibleCocktailShake(false)
    }
    const goBackRecommended = () => {
        props.setVisibleCocktailShake(false)
        props.setVisibleRecommended(true)
    }
    const onClickWhatsUp = () => {
        window.open('https://wa.me/message/LIVOT6WXBFOXH1');
    }
    return (


        <div style={{ textAlign: 'center', backgroundColor: '#F6ECE3' }} >

            <img style={{ width: '50%' }} src={logo} alt='logo' />

            <div style={{ textAlign: 'center', padding: '20px 20px', backgroundColor: 'red' }} >
                <FaCircleArrowRight onClick={goBack} style={{ float: 'right' }} size={'40px'} />
                <h3 style={{ margin: 'auto', width: '50%', color: 'white' }}>קוקטייל שייקים</h3>

            </div>


            <div class="row">
                <div onClick={()=>handleVisibleRecommended("היי ארצה להזמין חצי ליטר טרופיקל אייס")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">טרופיקל אייס</p>
                    <p className="description">קוקטייל שייק: אננס, מלון,לימון סחוט. </p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={tropicalIce} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר פסיפלוריקה ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פסיפלוריקה</p>
                    <p className="description">קוקטייל שייק:פסיפלורה,מנגו, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={pasiflora} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר ג'ננגו ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ג’ננגו</p>
                    <p className="description">קוקטייל שייק: מנגו, בננה,לימון סחוט</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={bananaMango} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר  סטרוברי וודקה")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">סטרוברי וודקה</p>
                    <p className="description">קוקטייל שייק: וודקה,תות,מייפל, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={strawberyVodka} alt='logo' />
                </div>
            </div>

            


            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={goBackRecommended} spy={true} smooth={true} offset={-15} duration={500} >
                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">מומלצים</p>
                    <p className="description"> </p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={tropicalIce} alt='logo' />
                </div>
            </div>
            <div class="row">
                <div  className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <Link to="OrderMenu" onClick={goBackCrushediceCocktailShake} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ברדי אלכוהול קפואים</p>
                    <p className="description">קוקטיילי ברד גרוסים.</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={crushedIceCocktails} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>

                    <Link to="OrderMenu" onClick={handleVisibleBottleMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בקבק לי </p>
                    <p className="description">כל סוגי המשקאות בבקבוק </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <Link to="OrderMenu" onClick={goToFoodMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">רוצה לאכול!</p>
                    <p className="description">אוכל בריא וטעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={food} alt='logo' />
                </div>
            </div>



        <div style={{display:'inline'}}>
                <Link to="OrderMenu" onClick={goBack} spy={true} smooth={true} offset={-15} duration={500} >
            <img onClick={goBack} style={{ margin:'10px 10px', height: '40%', width: '40%' }} src={backToMenuBtn} alt='logo' />
            </Link>
                <img onClick={handleVisibleRecommended} style={{ margin: '10px 10px',  height: '40%', width: '40%' }} src={orderBtn} alt='logo' />

            </div>










        </div>





    )
}

export default CocktailShake
