import React, { useState } from 'react'



function DropdownBtn(props) {
 
// const [selects,setSelects]=useState()
  
    return (
        <div>
           
            <select value={props.selects} onChange={e=>props.setSelects(e.target.value)} >
         
                <option >עברית</option>
                <option  >English</option>
                
            </select>

        </div>
    );
}

export default DropdownBtn;
