import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import '../OrderMenu.css'
import {Link} from 'react-scroll'
import logo from '../images/logo.png'
import recommend from '../images/5.png'
import cocktailMenu from '../images/6.png'
import shakeMenu from '../images/7.png'
import crushedIceCocktail from '../images/8.png'
import crushedIce from '../images/9.png'
import bottle from '../images/bottle.png'
import eat from '../images/11.png'
import button from '../images/אתר דף נחיתה שייקהול(390 x 844 פיקסל) (1).png'
import orderBtn from '../images/כפתור הזמנה.png'
import { IoArrowBackCircle } from "react-icons/io5";


const MenuView = (props) => {
   
    


    const goToWhatsup=()=>{
        window.open('https://wa.me/message/LIVOT6WXBFOXH1');
    }
    const handleVisibleRecommended=()=>{

        props.setVisibleRecommended(true)
        props.setVisibleMenu(false)
    }

    const handleVisibleCocktailShake = () => {
        console.log("click")
        props.setVisibleCocktailShake(true)
        props.setVisibleMenu(false)
    }

    const handleVisibleShake = () => {
        props.setVisibleShake(true)
        props.setVisibleMenu(false)
    }

    const handleVisibleCrushedIceCocktail = () => {
        props.setVisibleCrushedIceCocktail(true)
        props.setVisibleMenu(false)
    }

    const handleVisibleFoodMenu = () => {
        props.setVisibleFoodMenu(true)
        props.setVisibleMenu(false)
    }
    const handleVisibleBottleMenu = () => {
        console.log("visibleBottleInMenuView")
        props.setVisibleBottleMenu(true)
        props.setVisibleMenu(false)
    }

    return (


        <div style={{ textAlign: 'center', backgroundColor: '#F6ECE3' }} >

            <img style={{ width: '50%' }} src={logo} alt='logo' />

            <div style={{ padding: '20px 20px', backgroundColor: 'red' }} >
                <h3 style={{ color: 'white' }}>בחר קטגוריה מהתפריט</h3>
            </div>



            <div class="row">
                <div onClick={handleVisibleRecommended} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">המומלצים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '65%' }} src={recommend} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>

                    <Link to="OrderMenu" onClick={handleVisibleCocktailShake} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">קוקטייל שייקים</p>
                    <p className="description">שייקי אלכוהול פירות מפתיעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '65%' }} src={cocktailMenu} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleShake} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div  className="column middle" style={{}}>

                    <p className="title">שייקי פירות</p>
                    <p className="description">משקאות מפנקים ומרעננים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '50%' }} src={shakeMenu} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleCrushedIceCocktail} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ברדי אלכוהול קפואים</p>
                    <p className="description">קוקטיילי ברד גרוסים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '55%' }} src={crushedIce} alt='logo' />
                </div>
            </div>

            

            <div class="row">
                <div className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleBottleMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בקבק לי </p>
                    <p className="description">כל סוגי המשקאות בבקבוק </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleFoodMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>
                </div>
                <div className="column middle" style={{}}>

                    <p className="title">רוצה לאכול!</p>
                    <p className="description">אוכל בריא וטעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '100%' }} src={eat} alt='logo' />
                </div>

            </div>

            <img onClick={goToWhatsup} style={{ margin: '20px 20px', height: '40%', width: '40%' }} src={orderBtn} alt='logo' />












        </div>





    )
}

export default MenuView
