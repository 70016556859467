import React from 'react'
import './Hero.css'
import { Link } from "react-scroll"


import { FaRegArrowAltCircleDown } from "react-icons/fa";




import logo from './images/logo.png'
import order from './images/כפתור הזמינו.png'
import info from './images/כפתור מי אנחנו.png'

const Hero = () => {

    

    return (
        <div className='hero' >
            
            <div className='content'>
 <div style={{height:"15%"}}></div>

              
            <p style={{margin:'auto',fontSize:'larger', textAlign: 'center', width:'90%', color:"black"}}>“ככל שאתם חוגגים יותר את החיים, כך החיים ייתנו לכם יותר סיבות לחגוג”</p>

                     
            <div style={{height:"3%"}}></div>
           
            <div style={{ position:'relative', textAlign:'center'}} >
                    <img style={{ width:"55%"}} src={logo} alt='logo' />
                </div>

            <div style={{ height: "3%" }}></div>
          
                <div style={{  margin:'auto', width:"50%", borderBottom: "2px solid black" }}></div>
                    <h2 style={{textAlign:'center', width:'100%',paddingTop:"10px",paddingBottom:"10px", color: "black",  }}>משלוחי קוקטייל שייקים</h2>
            <div style={{ margin: 'auto', width: "50%", borderTop: "2px solid black" }}></div>               
               

            <div
                style={{
                    height: '20vh',
                    borderRadius: '0px 0px 2px 2px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                <div style={{ flex: 'auto', margin: 'auto', textAlign: 'center' }}> 
                    <Link to="about" spy={true} smooth={true} offset={20} duration={500} > 
                 <img style={{ width: '40%', margin: '10px' }} src={info} alt='logo' />  
                 </Link>
                 
                    <Link to="OrderMenu" spy={true} smooth={true} offset={-10} duration={500} > <img style={{ width: '40%', margin: '10px' }} src={order} alt='logo' /></Link></div>
            

     
           </div> 
           <div className='btnDown' >
                <Link to="about" spy={true} smooth={true} offset={20} duration={500} >
            <FaRegArrowAltCircleDown style={{margin:'auto'}} size={56} color={'black'} />
                </Link>
            </div>
           

           
      {/* <button class="button-42" >צפייה בתפריט</button> */}
     

        {/* <div class="grid">
        <img src={john} alt='john'  />
        <img src={john} alt='john'/>
         <img src={john} alt='john'/>
        <img src={john} alt='john' />
    
        
        
     
            </div>  */}
            </div>
            
        </div>
    )
}

export default Hero
