import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import '../OrderMenu.css'
import bottle from '../images/bottle.png'
import logo from '../images/logo.png'
import orderBtn from '../images/כפתור הזמנה.png'
import limonArak from '../images/9.png'
import lemongrass from '../images/lemonGrass.png'
import pasiflora from '../images/pasiflora.png'
import jinTonic from '../images/margarita.png'
import bananaTamar from '../images/bananatamar.png'
import tropicalIce from '../images/5.png'
import food from '../images/11.png'
import { FaCircleArrowRight } from "react-icons/fa6";
import backToMenuBtn from '../images/חזרה לתפריט.png'
import {Link} from 'react-scroll'
import { IoArrowBackCircle } from "react-icons/io5";
import strawberyVodka from '../images/tot.png'



const Recommended = (props) => {




    const handleVisibleRecommended = (text) => {
        window.open(`https://wa.me/972525233560?text=${text}`);
    }
    const goBack = () => {
        props.setVisibleRecommended(false)
        props.setVisibleMenu(true)
    }
    const goToFoodMenu = () => {
      
        props.setVisibleRecommended(false)
        props.setVisibleFoodMenu(true)
    }

    const handleVisibleBottleMenu = () => {
        props.setVisibleBottleMenu(true)
        props.setVisibleRecommended(false)
    }

    return (


        <div style={{ textAlign: 'center', backgroundColor: '#F6ECE3' }} >

            <img style={{ width: '50%' }} src={logo} alt='logo' />

            <div style={{ textAlign:'center', padding: '20px 20px', backgroundColor: 'red' }} >
                <FaCircleArrowRight onClick={goBack} style={{float:'right'}} size={'40px'} />
                <h3 style={{ margin:'auto', width:'50%', color: 'white' }}>המומלצים</h3>
               
            </div>



            

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר לימונארק")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">לימונארק </p>
                    <p className="description"> ברד גרוס:ארק,נענע,לימון. </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={limonArak} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר למון גראס מרגריטה")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">למון גראס </p>
                    <p className="description"> ברד גרוס:וודקה,נענע,לימון.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={lemongrass} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר טרופיקל אייס")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">טרופיקל אייס</p>
                    <p className="description">קוקטייל שייק: אננס, מלון,לימון סחוט. </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={tropicalIce} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר פסיפלוריקה")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פסיפלוריקה</p>
                    <p className="description">קוקטייל שייק:פסיפלורה,מנגו, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={pasiflora} alt='logo' />
                </div>
            </div>
            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר סטרוברי וודקה")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">סטרוברי וודקה</p>
                    <p className="description">קוקטייל שייק: וודקה,תות,מייפל, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '60%' }} src={strawberyVodka} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר ג'ין-טוניק")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ג’ין-טוניק </p>
                    <p className="description">ברד גרוס:גין,טוניק,לימון.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={jinTonic} alt='logo' />
                </div>
            </div>
            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין חצי ליטר בוסט שייק")} className="column left" style={{}}>


                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בוסט שייק </p>
                    <p className="description">שייק:בננה, תמר,סוייה ,ווניל</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={bananaTamar} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleBottleMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בקבק לי </p>
                    <p className="description">כל סוגי המשקאות בבקבוק </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div className="column left" style={{}}>

                    <Link to="OrderMenu" onClick={goToFoodMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">רוצה לאכול!</p>
                    <p className="description">אוכל בריא וטעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={food} alt='logo' />
                </div>
            </div>


            <div style={{ display: 'inline' }}>

                <Link to="OrderMenu" onClick={goBack} spy={true} smooth={true} offset={-15} duration={500} >
                <img onClick={goBack} style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={backToMenuBtn} alt='logo' />
                </Link>
                <img onClick={handleVisibleRecommended} style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={orderBtn} alt='logo' />

            </div>







            



            {/* <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">קוקטייל שייקים</p>
                    <p className="description">שייקי אלכוהול פירות מפתיעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '75%' }} src={cocktailMenu} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">שייקי פירות</p>
                    <p className="description">משקאות מפנקים ומרעננים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '55%' }} src={shakeMenu} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ברדי אלכוהול קפואים</p>
                    <p className="description">קוקטיילי ברד גרוסים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '55%' }} src={crushedIceCocktail} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ברדים קפואים</p>
                    <p className="description">ברדים גרוסים ומתוקים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '60%' }} src={crushedIce} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בקבק לי </p>
                    <p className="description">כל סוגי המשקאות בבקבוק </p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '110%', width: '40%' }} src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div className="column left" style={{}}>

                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                </div>
                <div className="column middle" style={{}}>

                    <p className="title">רוצה לאכול!</p>
                    <p className="description">אוכל טעים ובריא</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ height: '100%', width: '100%' }} src={eat} alt='logo' />
                </div>
               

            </div>  */}

            












        </div>





    )
}

export default Recommended
