import React,{useState} from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import Testimonials from './components/Testimonials'

import Footer from './components/Footer'
import Summary from './components/Summary'
import OrderMenu from './components/OrderMenu'
import Navbar2 from './components/Navbar2'
import MenuGallery from './components/MenuGallery'
// import VideoGallery from './components/VideoGallery'



function App() {
  const [hidden, setHidden] = useState(false)
  const[lastScroll,setLastScroll]=useState(0)
  
  const hideBarOnScrollDown=()=>{
      
      if(window.scrollY>=90){
          setHidden(true)
      }
      else{
          setHidden(false)
      }
  }

  window.addEventListener('scroll',hideBarOnScrollDown)

  // window.addEventListener('scroll', hideBarOnScrollDown)
  return (
    <div>
      <Navbar hidden={hidden} />
      <Hero />
      <About />
      {/* <VideoGallery /> */}
      <MenuGallery/>
      <Summary/>
      <OrderMenu/>
      
    
      <Testimonials />

      <Footer />
      <Navbar2/>

          </div>
  );
}

export default App;
