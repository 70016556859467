import React from 'react'
//import contact from './images/יצירת קשר.png'
import './Summary.css'
import cocktail from './images/cocktail.png'
import shake from './images/shakeGlass.png'
import food from './images/food.png'
import delivery from './images/delivery.png'


const Summary = () => {


    return (

         
        <div class="menuTab" id='Summary' >
           
          <h1 style={{textAlign:'center',marginBottom:'15px',paddingTop:'20px'}} > מה בתפריט</h1>
             <div style={{ margin: 'auto', marginBottom: '60px' ,marginTop: '20px', width: '40%', borderBottom: "2px solid black" }}></div>
        


            <div class="row">
                <div className="col" style={{}}>

                    {/* <div class="imagRight" >
                    <img  style={{}} src={shake} alt='logo' />
                        <span class="caption">שייקי פירות מרעננים sadsa dsadsa </span>
                    
                    </div> */}

                    <div class="imagLeft">
                        <img style={{}} src={shake} alt='logo' />
                        <span class="caption">שייקי פירות מרעננים</span>

                    </div>


                    <div class="imagLeft">
                    <img  style={{}} src={cocktail} alt='logo' />
                        <span class="caption">קוקטייל שייקים איכותיים ומפתיעים</span>

                    </div>

                </div>
               
               
               
                <div className="col" style={{}}>

                    {/* <div class="imagRight" >
                    <img  style={{}} src={food} alt='logo' />
                        <span class="caption"> sad dsadsa sda sda תפריט אוכל טעים בריא, ומהיר</span>
                   
                   </div> */}
                    <div class="imagLeft">
                        <img style={{}} src={food} alt='logo' />
                        <span class="caption">תפריט אוכל טעים בריא, ומהיר</span>

                    </div>


                   <div class="imagLeft">
                    <img  style={{}} src={delivery} alt='logo' /> 
                        <span class="caption">שירותי משלוחים שעוד לא ייצא לכם לקבל (ללא עלות)</span>
                    </div>
               
            </div>
            </div>




            {/* <table className='content-table' >
            <div className='section'>
             <tr>
                   
                    <th><img style={{   }} src={cocktail} alt='logo' /> </th>
                    <th> <img style={{   }} src={shake} alt='logo' /></th>
                </tr>
           
                <tr >
                    
                    
                        <td>קוקטייל שייקים איכותיים ומפתיעים</td>
                        <td>שייקי פירות מרעננים</td>
                </tr>
                </div>

              


                <div className='section'>
                <tr >

                    <th><img style={{  }} src={food} alt='logo' /> </th>
                    <th> <img style={{  }} src={delivery} alt='logo' /></th>
                </tr>

                <tr>

                    <td>תפריט אוכל טעים בריא, ומהיר</td>
                    <td>שירותי משלוחים שעוד לא ייצא לכם לקבל (ללא עלות)</td>
                    
                </tr>
                </div>

           </table> */}

           
               
        

            
                {/* <div style={{ flex: 'auto', margin: 'auto', textAlign: 'center' }}>  <img style={{ width: '30%', margin: '10px' }} src={cocktail} alt='logo' />   <img style={{ width: '25%', margin: '10px' }} src={shake} alt='logo' /></div> */}



            </div> 





    )
}

export default Summary
