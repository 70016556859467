import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from "react-scroll"
import logo from './images/logo.png'
import location from './images/location.png'
import tiktok from './images/tiktok.png'
import instagram from './images/instagram.png'
import whatsup from './images/whatsap.png'
import btn from './images/כפתור הזמנה.png'



import './Navbar2.css'

const Navbar2 = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const onClickWhatsUp=()=>{
        window.open('https://wa.me/message/LIVOT6WXBFOXH1');
    }
    const onClickTiktok=()=>{
        window.open('https://www.tiktok.com/@shakehol?_t=8niRyplBHGi&_r=1')
    }
    const onClickInstagram = () => {
        window.open('https://www.instagram.com/shakehol.tlv?igsh=MWY4bXdhZHFuZHY4MQ==');
    }
    const closeMenu = () => setClick(false)

    return (



        
      


       





        <div class="navbar2">
        <div class='button-30'>
            <div class="row">
                <div className="column2 left2" style={{}}>

                    <Link to="hero" spy={true} smooth={true} offset={-10} duration={500} onClick={onClickWhatsUp}> <img class="imgIcon" src={whatsup} alt='logo' /></Link>

                </div>
                <div className="column2 middle2" style={{}}>

                        <Link to="hero" spy={true} smooth={true} offset={-10} duration={500} onClick={onClickInstagram}> <img class="imgIcon" src={instagram} alt='logo' /></Link>
                </div>
                {/* <div className="column2 right2" style={{}}>

                        <Link to="hero" spy={true} smooth={true} offset={-10} duration={500} onClick={closeMenu}> <img class="imgIcon"  src={tiktok} alt='logo' /></Link>
                </div> */}
                <div className="column2 right2" style={{}}>

                        <Link to="footer" spy={true} smooth={true} offset={250} duration={500} onClick={onClickTiktok}> <img class="imgIcon" src={tiktok} alt='logo' /></Link>
                </div>
                <div className="column2 right2" style={{}}>

                        <Link to="footer" spy={true} smooth={true} offset={250} duration={500} onClick={closeMenu}> <img class="imgIcon" src={location} alt='logo' /></Link>
                </div>
            
            
                <div className="column2 leftBtn" style={{}}>

                    <Link to="OrderMenu" spy={true} smooth={true} offset={-10} duration={500} onClick={closeMenu}> <img class="imgBtn"  src={btn} alt='logo' /></Link>
                </div>

         

            </div>
            </div>
        </div>
    )
}

export default Navbar2
