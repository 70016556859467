import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import '../OrderMenu.css'
import backToMenuBtn from '../images/חזרה לתפריט.png'
import bottle from '../images/bottle.png'
import logo from '../images/logo.png'
import orderBtn from '../images/כפתור הזמנה.png'
import limonArak from '../images/9.png'
import lemongrass from '../images/lemonGrass.png'
import pasiflora from '../images/pasiflora.png'
import jinTonic from '../images/margarita.png'
import bananaTamar from '../images/bananatamar.png'
import tropicalIce from '../images/5.png'
import food from '../images/11.png'
import powerSower from '../images/Sower.png'
import cocktailShake from '../images/6.png'
import padKapao from '../images/padKapai.png'
import papaya from '../images/papaya.png'
import egroll from '../images/egroll.png'
import loti from '../images/loti.png'
import {Link} from 'react-scroll'

import { FaCircleArrowRight } from "react-icons/fa6";



import { IoArrowBackCircle } from "react-icons/io5";


const FoodMenu = (props) => {




    const handleVisibleRecommended = (text) => {
        window.open(`https://wa.me/972525233560?text=${text}`);
    }
    const goBack = () => {
        props.setVisibleFoodMenu(false)
        props.setVisibleMenu(true)
    }
    const goBackRecommended = () => {
        props.setVisibleFoodMenu(false)
        props.setVisibleRecommended(true)
    }
    const goBackCocktailShake = () => {
        props.setVisibleFoodMenu(false)
        props.setVisibleCocktailShake(true)
    }
    const handleVisibleBottleMenu = () => {
        props.setVisibleBottleMenu(true)
        props.setVisibleFoodMenu(false)
    }

    return (


        <div style={{ textAlign: 'center', backgroundColor: '#F6ECE3' }} >

            <img style={{ width: '50%' }} src={logo} alt='logo' />

            <div style={{ textAlign: 'center', padding: '20px 20px', backgroundColor: 'red' }} >
                <FaCircleArrowRight onClick={goBack} style={{ float: 'right' }} size={'40px'} />
                <h3 style={{ margin: 'auto', width: '50%', color: 'white' }}>תפריט אוכל</h3>

            </div>






            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין פאד תאי")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פאד תאי</p>
                    <p className="description">אטריות אורז מוקפצות עם נבטים,בצל ירוק ובוטנים ברוטב סויה ותמרינדי.</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={food} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין קאו פאד")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">קאו פאד</p>
                    <p className="description">אורז מוקפץ עם ביצה,ברוקולי,גזר,בצל ועוף ברוטב סויה</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={padKapao} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין סלט פפאיה ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">סלט פפאיה</p>
                    <p className="description">סלט פפאיה עם עגבניות, בוטנים,לימון וצ’ילי</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={papaya} alt='logo' />
                </div>
            </div>



            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין אגרול תאילנדי ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">אגרול תאילנדי</p>
                    <p className="description">אגרול במילוי אטריות שעועית וירקות</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={egroll} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין  בננה לוטי")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בננה לוטי</p>
                    <p className="description">לוטי במילוי בננה ושוקולד</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{ margin: 'auto', height: '100%', width: '100%' }} src={loti} alt='logo' />
                </div>
            </div>
            <div class="row">
                <div  className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <Link to="OrderMenu" onClick={goBackRecommended} spy={true} smooth={true} offset={-15} duration={500} > 
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">מומלצים</p>
                    <p className="description"></p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={tropicalIce} alt='logo' />
                </div>
            </div>
            <div class="row">
                <div  className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <Link to="OrderMenu" onClick={goBackCocktailShake} spy={true} smooth={true} offset={-15} duration={500} > 
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">קוקטייל שייקים</p>
                    <p className="description">שייקי אלכוהול פירות מפתיעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='glassImg' src={cocktailShake} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={handleVisibleBottleMenu} spy={true} smooth={true} offset={-15} duration={500} > 
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בקבק לי </p>
                    <p className="description">כל סוגי המשקאות בבקבוק </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

         



           







         

            <div style={{ display: 'inline' }}>

                 <Link to="OrderMenu" onClick={goBack} spy={true} smooth={true} offset={-15} duration={500} > 
                <img onClick={goBack} style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={backToMenuBtn} alt='logo' />
               </Link> 
                <img onClick={handleVisibleRecommended} style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={orderBtn} alt='logo' />

            </div>










        </div>





    )
}

export default FoodMenu
