import React, { useState } from 'react'
import './Footer.css'
import image from './images/contactUs.png'
import logo from './images/logo.png'
import button from './images/btnSend.png'
//  import emailjs from 'emailjs/browser'

import axios from 'axios'

const Footer = () => {

    const handleSubmit=async (e)=>{
        e.preventDefault()


    //     const serviceID = 'service_l5rr9zp'
    //     const publicKey = 'fUx0V1CKCSuHzYZX3'
    //     const templateId ='template_rvcnh9r'

    //     const data ={
    //     service_id :serviceID,
    //     user_id : publicKey,
    //     template_id : templateId,

    //     templateParams : {
    //         from_name: name,
    //         from_email: email,
    //         from_phone: num,
    //         to_name:'שייקהול',
    //         message: text
    //     }
    //     }
    //     console.log(data)


    //    await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
    //         type: 'POST',
    //         data:data,
    //         contentType: 'application/json'
    //     }).done(function () {
    //         alert('Your mail is sent!');
    //     }).fail(function (error) {
    //         alert('Oops... ' + JSON.stringify(error));
    //     });
    //     // const res = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
            
        
    //     //     .then(function (response) {
    //     //         console.log(response);
    //     //     })
    //     //     .catch(function (error) {
    //     //         console.log(error);
    //     //     });
       
    
        setEmail("")
        setName("")
        setNum("")
        setText("")
        
        console.log("SUBMIT")
    }


    const [name,setName]=useState();
    const[email,setEmail]=useState();
    const[num,setNum]=useState();
    const [text, setText] = useState();

   
    
    return (
        <div className='footer' id='footer'>

            <form className="form" onSubmit={handleSubmit}>
                <img style={{width:'50%'}} src={image} alt='logo' />
                <h1 style={{ color:'black', textAlign: 'center', marginBottom: '5px', paddingTop: '15px' }} >יצירת קשר</h1>
                <div style={{ margin: 'auto', marginBottom: '20px', marginTop: '20px', width: '40%', borderBottom: "2px solid black" }}></div>
                
                <input type="text" className="name formEntry" placeholder="שם מלא" value={name}
                onChange={(e)=>setName(e.target.value)}
                 />
                <input type="number" className="email formEntry" placeholder="פאלפון" value={num} required 
                    onChange={(e) => setNum(e.target.value)}
                />
                <input type="email" className="email formEntry" placeholder="מייל" value={email} required
                    onChange={(e) => setEmail(e.target.value)}
                 />
                <textarea className="message formEntry" placeholder="כתבו לנו..." value={text}
                    onChange={(e) => setText(e.target.value)}
                ></textarea>
                <label style={{ color: 'grey' }} htmlFor="terms">
                  
                  
                   
                </label>
                <br />
                <div onClick={handleSubmit}>
                <img className='orderBtn' style={{ width: '60%' }} src={button} alt='logo' />
                </div>

                <h1 style={{ color: 'black', textAlign: 'center', marginBottom: '5px', paddingTop: '15px' }} >052-5233560</h1>
                <h3 style={{color:'black'}} >כתובת: שלום עליכם 14,תל אביב</h3>
                
                
                <div style={{ margin: 'auto', marginBottom: '20px', marginTop: '20px', width: '40%', borderBottom: "2px solid black" }}></div>

                
                <img style={{ width: '60%', marginBottom:'100px'}} src={logo} alt='logo' />
           
            </form>

                

        </div>
    )
}

export default Footer
