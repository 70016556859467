import React from 'react'
import contact from './images/יצירת קשר.png'
import './About.css'
import { Link } from 'react-scroll'
import menuBtn from './images/צפייה בתפריט.png'

import ImageGallery from "react-image-gallery";
import imageBckground from './images/whoAreWe.png'
const About = () => {
   

    
    
    return (
        
        <div className='about' id='about'>


            <img className='imageBackground'  src={imageBckground} alt='logo' />
            {/* <h1 style={{ color:'#D0D5DA'}}> מי אנחנו?</h1>
            <div style={{ textAlign: 'right', marginTop: '20px', width: '30%', borderBottom: "2px solid #D0D5DA", height:"10%"}}></div>
            <div className='container'>
               
                <div className='col-2'>
                    <div style={{ background: 'rgba(204, 204, 204, 0.5)' }}>
                    <p >בר קוקטייל שייקים מרענן שמגיע עד אליכם לחוף!</p>
                        <p style={{ marginBottom: '10px ' }} >אחרי התייצבות במילואים מה-7 באוקטובר ובילוי של כמה חודשים טובים בגבול הצפון, החלטתי לפתוח שירותי משלוחים ייחודיים לחופי תל אביב, המציעים חוויית מלצרות לכל מקום שבו אתם יושבים .</p>
                        <p style={{ marginBottom: '10px ' }}>לאחר למעלה משנתיים של עבודה בברים בתל אביב וביקורים במגוון מקומות טרופיים בעולם, החלטתי להביא חוויה ייחודית לארץ שלנו, כדי שנוכל ליהנות משירותי חופשה גם כאן, במיוחד עכשיו, כאשר בחו"ל פחות מסמפטים אותנו.</p>
                    
                    <p>אנחנו מתמחים ביצירת קוקטיילים ושייקים איכותיים וטעימים, שמביאים את החוויה של בר יוקרתי ישירות אליכם, בכל מקום שבו אתם נמצאים. בין אם אתם מארגנים מסיבת חוף, יום כיף עם החברים או סתם רוצים ליהנות ממשקה קר ומרענן בשמש, אנחנו כאן כדי להפוך את החוויה שלכם למושלמת.</p>
                   
                    <h3 style={{margin:'10px 10px'}}>מה אנחנו מציעים?</h3>
                    
                        <b>מגוון קוקטיילים ושייקים:</b>
                        <p> תפריט עשיר עם קוקטיילים קלאסיים, שייקים מרעננים, ומשקאות ייחודיים בהתאמה אישית.</p>
                        <b>איכות בלתי מתפשרת: </b>
                    <p>כל המשקאות שלנו מיוצרים מחומרי הגלם האיכותיים ביותר, עם פירות טריים ומיצים טבעיים.</p>
                    <b>שירות עד החוף: </b>
                    <p>אנחנו מגיעים עד אליכם, לכל חוף, עם שירות משלוחים מהיר ואמין.</p>
                        <b>איפה אנחנו יושבים?:</b>
                        <p>מוזמנים לבוא לשבת אצלנו בשלום עליכם 14 תל אביב.</p>
                    <p>בואו ליהנות מקוקטייל מרענן ושייק טעים מתחת לשמש הקיצית! אנחנו כאן כדי להפוך את היום שלכם למיוחד ולמרענן יותר.</p>
                    <p>לפרטים נוספים והזמנות,   צפו בתפריט!! 🍹🏖️</p>
                    </div>
                    <div style={{ float: 'right', marginRight: '0px',
                            marginLeft: 'auto' }}>
                        <Link to="OrderMenu" spy={true} smooth={true} offset={20} duration={500} > 
                    <img style={{  width: '80%', marginTop: '50px', marginBottom: '110px' }} src={menuBtn} alt='logo' />
                        </Link>
                    </div>
                </div>
             
            </div>
 */}

          
        
        </div>
        

    )
}

export default About
