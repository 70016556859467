import React,{useState} from 'react'
import contact from './images/יצירת קשר.png'
import './About.css'
import { Link } from 'react-scroll'
import menuBtn from './images/צפייה בתפריט.png'
import imageBckground from './images/Photo Collage Beach Summer Vibes Instagram Story.png'
import bananaTamar from './images/bananatamar.png'
import tropicalIce from './images/5.png'
import food from './images/11.png'
import bananaMango from './images/mangoBanana.png'
import strawberyVodka from './images/tot.png'
import crushedIceCocktails from './images/9.png'
import halfLiter from './images/halfMenuHe.6c251781.png'
import bottle from './images/bottleMenuHe.7da83470.png'
import './MenuGallery.css'
import foodMenu from './images/foodMenu.png'
import orderBtn from './images/כפתור הזמינו.png'
import smallMenu from './images/resMenuHe.42c3672c.png'
import englishSmall from './images/resMenuEn.3a9d500c.png'
import englishMedium from './images/halfMenuEn.c9ccb481.png'
import englishBig from './images/bottleMenuEn.3f11adf6.png'
import DropdownBtn from './DropdownBtn'
const MenuGallery = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [selects, setSelects] = useState("עברית")
    const [menus,setMenus]=useState();

    const SetSelects=(value)=>{
        setSelects(value)

    }
    const englishMenu=()=>{
        
        let arr=[]
        arr[0]=slides[3]
        arr[1] = slides[4]
        arr[2] = slides[5]
        setMenus(arr);
        console.log("english")

    }
    const hebrawMenu = () => {

        let arr = []
        arr[0] = slides[0]
        arr[1] = slides[1]
        arr[2] = slides[2]
        setMenus(arr);
        

    }
    const onClickWhatsUp = () => {
        window.open('https://wa.me/message/LIVOT6WXBFOXH1');
    }
    const slides = [
       
        { src: smallMenu, alt: 'תפריט מסעדה' },
        { src: halfLiter, alt: 'תפריט כוסות-חצי ליטר' },
        { src: bottle, alt: 'תפריט בקבוקים-ליטר' },
        { src: foodMenu, alt: 'תפריט אוכל' },
        // { src: englishSmall, alt: 'Restaurant Menu' },
        // { src: englishMedium, alt: 'Half-Liter Cups Menu'},
        // { src: englishBig, alt: 'Liter Bottles Menu' },
       
        
       
    ];
    const slidesEnglish = [

        { src: englishSmall, alt: 'Restaurant Menu' },
        { src: englishMedium, alt: 'Half-Liter Cups Menu' },
        { src: englishBig, alt: 'Liter Bottles Menu' },
        { src: foodMenu, alt: 'Food Menu' },



    ];

    const plusSlides = (n) => {
        let newSlide = currentSlide + n;
        if (newSlide > slides.length) newSlide = 1;
        if (newSlide < 1) newSlide = slides.length;
        setCurrentSlide(newSlide);
    };

    const showSlide = (n) => setCurrentSlide(n);

    return (

        <div id="Menus" className="container">
           
            <div className="caption-container">
                {
                    selects === 'עברית' ? <p id="caption">{slides[currentSlide - 1]?.alt}</p> :
                        <p id="caption">{slidesEnglish[currentSlide - 1]?.alt}</p>

                }
                
                <DropdownBtn selects={selects} setSelects={SetSelects} hebrawMenu={hebrawMenu} englishMenu={englishMenu}/>
            </div>
            {(selects==='עברית')? slides.map((slide, index) => (
                <div
                    key={index}
                    className="mySlides"
                    style={{ display: currentSlide === index + 1 ? 'block' : 'none' }}
                >
                    <div className="numbertext">{index + 1} / {slides.length}</div>
                    <img src={slide.src} style={{ width: '100%' }} alt={slide.alt} />
                </div>
            ))
            :
                slidesEnglish.map((slide, index) => (
                    <div
                        key={index}
                        className="mySlides"
                        style={{ display: currentSlide === index + 1 ? 'block' : 'none' }}
                    >
                        <div className="numbertext">{index + 1} / {slidesEnglish.length}</div>
                        <img src={slide.src} style={{ width: '100%' }} alt={slide.alt} />
                    </div>
                ))
            }

            <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
            <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>

            <div className="caption-container">
               
                <img onClick={onClickWhatsUp} className='orderBtn' src={orderBtn}/>   
            </div>

            {/* <div  className="rowGallery">
                {slides.map((slide, index) => (
                    <div className="columnGallery" key={index}>
                        <img
                            className="demo cursor"
                            src={slide.src.replace('_wide', '')}
                            style={{ width: '100%' }}
                            onClick={() => showSlide(index + 1)}
                            alt={slide.alt}
                        />
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default MenuGallery;

