import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import './OrderMenu.css'

import logo from './images/logo.png'
import recommend from './images/5.png'
import cocktailMenu from './images/6.png'
import shakeMenu from './images/7.png'
import crushedIceCocktail from './images/8.png'
import crushedIce from './images/9.png'
import bottle from './images/10.png'
import eat from './images/11.png'
import button from './images/אתר דף נחיתה שייקהול(390 x 844 פיקסל) (1).png'
import orderBtn from './images/כפתור הזמנה.png'
import { IoArrowBackCircle } from "react-icons/io5";
import MenuView from './Menu/MenuView'
import Recommended from './Menu/Recommended'
import CocktailShake from './Menu/CockTailShake'
import Shake from './Menu/Shake'
import CrushedIceCocktails from './Menu/CrushedIceCocktails'
import FoodMenu from './Menu/FoodMenu'
import BottleMenu from './Menu/BottleMenu'


const OrderMenu = () => {
  
const[visibleMenu,setVisibleMenu]=useState(true)
// הצגת מומלצים
const [visibleRecommended,setVisibleRecommended]=useState(false)
// הצגת קוקטייל שייקים
const [visibleCocktailShake,setVisibleCocktailShake]=useState(false)
    // הצגת  שייקים
    const [visibleShake, setVisibleShake] = useState(false)
    // הצגת קוקטייל ברדים
    const [visibleCrushedIceCocktail, setVisibleCrushedIceCocktail] = useState(false)
    //בקבוקים
    const [VisibleBottleMenu, setVisibleBottleMenu] = useState(false)
    
    //אוכל
    const [visibleFoodMenu,setVisibleFoodMenu]=useState(false)
// תפריט ראשי
const SetVisibleMenu=(bool)=>{
    setVisibleMenu(bool)
}
// מומלצים
    const SetVisibleRecommended = (bool) => {
        setVisibleRecommended(bool)
    }

  //קוקטייל שייקים
    const SetVisibleCocktailShake = (bool) => {
        setVisibleCocktailShake(bool)
    }  

    // שייקים
    const SetVisibleShake = (bool) => {
        setVisibleShake(bool)
    }   


    //קוקטייל ברדים
    const SetVisibleCrushedIceCocktail = (bool) => {
        setVisibleCrushedIceCocktail(bool)
    } 

    //בקבוק
    const SetVisibleBottleMenu = (bool) => {
        setVisibleBottleMenu(bool)
    } 

    // אוכל
    const SetVisibleFoodMenu = (bool) => {
        setVisibleFoodMenu(bool)
    } 


    return (
<>
<div id='OrderMenu'>
            {visibleMenu && <MenuView visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleShake={SetVisibleShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} setVisibleFoodMenu={SetVisibleFoodMenu} setVisibleBottleMenu={SetVisibleBottleMenu} />}
             {visibleRecommended && <Recommended  setVisibleBottleMenu={SetVisibleBottleMenu} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleFoodMenu={setVisibleFoodMenu} />}
                {visibleCocktailShake && <CocktailShake setVisibleBottleMenu={SetVisibleBottleMenu} visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} setVisibleFoodMenu={SetVisibleFoodMenu} />}
                {visibleShake && <Shake setVisibleBottleMenu={SetVisibleBottleMenu} setVisibleFoodMenu={SetVisibleFoodMenu} visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleShake={SetVisibleShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} />}
                {visibleCrushedIceCocktail && <CrushedIceCocktails  setVisibleBottleMenu={SetVisibleBottleMenu} visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleShake={SetVisibleShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} setVisibleFoodMenu={SetVisibleFoodMenu}  />}
            {VisibleBottleMenu && <BottleMenu visibleMenu={visibleMenu}  setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleShake={SetVisibleShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} setVisibleFoodMenu={SetVisibleFoodMenu} setVisibleBottleMenu={SetVisibleBottleMenu} />}
                {visibleFoodMenu && <FoodMenu setVisibleBottleMenu={SetVisibleBottleMenu} visibleMenu={visibleMenu} setVisibleMenu={SetVisibleMenu} setVisibleRecommended={SetVisibleRecommended} setVisibleCocktailShake={SetVisibleCocktailShake} setVisibleShake={SetVisibleShake} setVisibleCrushedIceCocktail={SetVisibleCrushedIceCocktail} setVisibleFoodMenu={SetVisibleFoodMenu} />}
            </div>
   </> 
        // <div style={{ textAlign:'center', backgroundColor: '#F6ECE3' }} >

        //     <img style={{width:'50%'}} src={logo} alt='logo' />

        //     <div style={{padding:'20px 20px', backgroundColor:'red'}} >
        //         <h3 style={{color:'white'}}>בחר קטגוריה מהתפריט</h3>
        //     </div>



        //     <div class="row">
        //         <div className="column left" style={{ }}>
                   
        //             {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{  }}>
                   
        //             <p className="title">המומלצים</p>
        //         </div>
        //         <div className="column right" style={{ }}>
                    
        //             <img style={{ margin:'auto', height: '100%', width: '75%' }} src={recommend} alt='logo' />
        //         </div>
        //     </div>

        //     <div class="row">
        //         <div className="column left" style={{ }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{  }}>

        //             <p className="title">קוקטייל שייקים</p>
        //             <p className="description">שייקי אלכוהול פירות מפתיעים</p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '100%', width: '75%' }} src={cocktailMenu} alt='logo' />
        //         </div>
        //     </div>


        //     <div class="row">
        //         <div className="column left" style={{ }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{ }}>

        //             <p className="title">שייקי פירות</p>
        //             <p className="description">משקאות מפנקים ומרעננים</p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '100%', width: '55%' }} src={shakeMenu} alt='logo' />
        //         </div>
        //     </div>

        //     <div class="row">
        //         <div className="column left" style={{ }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{  }}>

        //             <p className="title">ברדי אלכוהול קפואים</p>
        //             <p className="description">קוקטיילי ברד גרוסים</p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '100%', width: '55%' }} src={crushedIceCocktail} alt='logo' />
        //         </div>
        //     </div>

        //     <div class="row">
        //         <div className="column left" style={{  }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{ }}>

        //             <p className="title">ברדים קפואים</p>
        //             <p className="description">ברדים גרוסים ומתוקים</p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '100%', width: '60%' }} src={crushedIce} alt='logo' />
        //         </div>
        //     </div>

        //     <div class="row">
        //         <div className="column left" style={{  }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

        //         </div>
        //         <div className="column middle" style={{ }}>

        //             <p className="title">בקבק לי </p>
        //             <p className="description">כל סוגי המשקאות בבקבוק </p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '110%', width: '40%' }} src={bottle} alt='logo' />
        //         </div>
        //     </div>

        //     <div class="row">
        //         <div className="column left" style={{ }}>

        //             <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
        //         </div>
        //         <div className="column middle" style={{  }}>

        //             <p className="title">רוצה לאכול!</p>
        //             <p className="description">אוכל טעים ובריא</p>
        //         </div>
        //         <div className="column right" style={{ }}>

        //             <img style={{ height: '100%', width: '100%' }} src={eat} alt='logo' />
        //         </div>
                
        //     </div>

        //     <img style={{ margin:'20px 20px', height: '40%', width: '40%' }} src={orderBtn} alt='logo' />

           



           






        // </div>





    )
}

export default OrderMenu
