import React, { useState } from 'react'
//import contact from './images/יצירת קשר.png'
import '../OrderMenu.css'
import { Link } from "react-scroll"
import logo from '../images/logo.png'

import orderBtn from '../images/כפתור הזמנה.png'
import limonArak from '../images/9.png'
import lemongrass from '../images/lemonGrass.png'
import pasiflora from '../images/pasiflora.png'
import jinTonic from '../images/margarita.png'
import bananaTamar from '../images/bananatamar.png'
import tropicalIce from '../images/5.png'
import food from '../images/11.png'
import bananaMango from '../images/mangoBanana.png'
import strawberyVodka from '../images/tot.png'
import bottle from '../images/bottle.png'
import boostShake from '../images/bananatamar.png'
import magicFrot from '../images/mangoPina.png'
import crushedIceCocktails from '../images/9.png'
import { FaCircleArrowRight } from "react-icons/fa6";

import backToMenuBtn from '../images/חזרה לתפריט.png'
import { IoArrowBackCircle } from "react-icons/io5";


const BottleMenu = (props) => {




    const handleVisibleRecommended = (text) => {
       
        window.open(`https://wa.me/972525233560?text=${text}`);
    }
    const handleVisibleCocktailShake = () => {
        props.setVisibleRecommended(true)
        props.setVisibleMenu(false)
    }
    const goBack = () => {
        props.setVisibleBottleMenu(false)
        props.setVisibleMenu(true)
    }

    const goToFoodMenu = () => {
        console.log("foodMenu")
        props.setVisibleBottleMenu(false)
        props.setVisibleFoodMenu(true)
    }
    return (


        <div style={{ textAlign: 'center', backgroundColor: '#F6ECE3' }} >

            <img style={{ width: '50%' }} src={logo} alt='logo' />

            <div style={{ textAlign: 'center', padding: '20px 20px', backgroundColor: 'red' }} >
                <FaCircleArrowRight onClick={goBack} style={{ float: 'right' }} size={'40px'} />
                <h3 style={{ margin: 'auto', width: '50%', color: 'white' }}>בקבק לי </h3>

            </div>


            <div class="row">
                <div onClick={()=>handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של טרופיקל אייס")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר טרופיקל אייס</p>
                    <p className="description">קוקטייל שייק: אננס, מלון,לימון סחוט. </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg'  src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של  פסיפלוריקה")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר פסיפלוריקה</p>
                    <p className="description">קוקטייל שייק:פסיפלורה,מנגו, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של ג'ננגו ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר ג’ננגו </p>
                    <p className="description">קוקטייל שייק: מנגו, בננה,לימון סחוט</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של  סטרוברי וודקה")} className="column left" style={{}}>
                   
                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר סטרוברי וודקה</p>
                    <p className="description">קוקטייל שייק: וודקה,תות,מייפל, לימון סחוט.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של  לימונארק")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר לימוןארק   </p>
                    <p className="description"> ברד גרוס:ארק,נענע,לימון. </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של למון גראס מרגריטה ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר למון גראס</p>
                    <p className="description"> ברד גרוס:וודקה,נענע,לימון.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>



            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של פאוור סאוור ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר פאוור סאוור</p>
                    <p className="description">ברד גרוס:וויסקי,ג’ינג’ר אייל,לימון.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>


            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של ג'ין -טוניק ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">ליטר גין-טוניק </p>
                    <p className="description">ברד גרוס:גין,טוניק,לימון.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">

                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של טרופיקנה ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">טרופיקנה</p>
                    <p className="description"> שייק:אננס, מלון, על בסיס מיץ לימונדה. </p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של פנטסטיפלורה ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פנטסטיפלורה</p>
                    <p className="description">שייק:פסיפלורה, מנגו על בסיס מיץ לימונדה.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של פאנ'צ ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פאנצ’</p>
                    <p className="description">שייק:מנגו, בננה,לימון סחוט על בסיס לימונדה/חלב/סוייה וניל.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של פרדייז ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">פרדייז </p>
                    <p className="description">  שייק:תות,בננה על בסיס חלב/סוייה וניל.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>




            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של בוסט שייק ")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">בוסט שייק </p>
                    <p className="description">שייק:בננה, תמר,סוייה ,ווניל</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            <div class="row">
                <div onClick={() => handleVisibleRecommended("היי ארצה להזמין בקבוק ליטר של  מג'יק פרוט")} className="column left" style={{}}>

                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">מג’יק פרוט</p>
                    <p className="description">שייק:מנגו,אננס,סוייה,ווניל.</p>
                </div>
                <div className="column right" style={{}}>

                    <img className='bottleImg' src={bottle} alt='logo' />
                </div>
            </div>

            



           
            <div class="row">
                <div  className="column left" style={{}}>
                    <Link to="OrderMenu" onClick={goToFoodMenu} spy={true} smooth={true} offset={-15} duration={500} >
                    {/* <img style={{  height: '40%', width: '60%' }} src={button} alt='logo' /> */}
                    <IoArrowBackCircle style={{ margin: 'auto' }} size={'40px'} />
                    </Link>

                </div>
                <div className="column middle" style={{}}>

                    <p className="title">רוצה לאכול!</p>
                    <p className="description">אוכל בריא וטעים</p>
                </div>
                <div className="column right" style={{}}>

                    <img style={{margin:'auto',width:'100%',height:'100%'}} src={food} alt='logo' />
                </div>
            </div>


            <div style={{ display: 'inline' }}>
                <Link to="OrderMenu" onClick={goBack} spy={true} smooth={true} offset={-15} duration={500} >
                <img  style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={backToMenuBtn} alt='logo' />
                 </Link> 
                <img onClick={handleVisibleRecommended} style={{ margin: '10px 10px', height: '40%', width: '40%' }} src={orderBtn} alt='logo' />

            </div>








        </div>





    )
}

export default BottleMenu
